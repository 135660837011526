<template>
  <div class="content">
    <div>
      <div>
        <div class="row">
          <div class="col">
            <input class="mb-2 form-control" type="text" v-model="template.name" placeholder="Template Name" :class="{ 'is-invalid': errors.name }" />
            <div v-for="(error, k) in errors.name" :key="'errname'+k" class="invalid-feedback" v-html="errors.name[k]"></div>
          </div>
          <div class="col">
            <input class="mb-2 form-control" type="text" v-model="template.description" placeholder="Template Description" />
            <div v-for="(error, k) in errors.description" :key="'errdes'+k" class="invalid-feedback" v-html="errors.description[k]"></div>
          </div>
          <div class="col">
            <input type="file" class="mb-2 form-control" :class="{ 'is-invalid': errors.template }" @change="onFileChange" />
            <div v-for="(error, k) in errors.template" :key="'errtem'+k" class="invalid-feedback" v-html="errors.template[k]"></div>
          </div>
          <div class="col">
            <button class="vave-btn btn-blue" @click="uploadTemplate()">Upload</button>
          </div>
          <div class="col-auto">
            <router-link :to="{ name: 'emailsDocumentation' }" class="mb-4 vave-btn">Documentation</router-link>
          </div>
        </div>
      </div>

      <div class="table-container">
        <table class="table">
          <thead class="head">
            <tr>
              <th class="ps-4">Id</th>
              <th>Name</th>
              <th>Description</th>
              <th>Status</th>
              <th>Models</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="template in templates.data" :key="template.id">
              <td class="ps-4">{{template.id}}</td>
              <td>{{template.name}}</td>
              <td>{{template.description}}</td>
              <td>{{template.status}}</td>
              <td>
                <span v-if="template.status == 'succeeded' || template.status == 'failed'">{{template.models.join(', ')}}</span>
                <span v-else>
                  <div class="spinner-border spinner-border-sm ms-2" role="status"></div>
                </span>
              </td>
              <td>
                <button class="vave-btn" @click="showPreview(template)" v-if="template.status == 'succeeded'">
                  <i class="fa fa-eye me-1"></i> Preview
                </button>
                <!-- Dont delete old email templtaes, or thy will break existing automations -->
                <!-- <div class="vave-btn btn-red" style="float:right;" @click="deleteTemplate(template.id)">delete</div> -->
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <pagination class="paginator" :limit="4" align="center" :data="templates" @pagination-change-page="getTemplates"></pagination>
    </div>

    <widget-config-modal ref="emailPreview">
      <template v-slot:title v-if="previewingTemplate">Preview {{previewingTemplate.name}} ({{previewingTemplate.slug}})</template>
      <template v-slot:body>
        <div class="h-100" style="position:relative;">
          <iframe v-if="previewingTemplate" class="preview-iframe" :src="baseUrl + '/api/admin/email-templates/'+ previewingTemplate.id + '/preview'"></iframe>
        </div>
      </template>
    </widget-config-modal>
  </div>
</template>

<script>
import axios from "./../http.js";
import pagination from "laravel-vue-pagination";
import MqttClient from "./../mqtt.js";
import WidgetConfigModal from "./widgets/WidgetConfigModal";
import EventBus from "./../bus.js";

export default {
  data() {
    return {
      template: {
        name: "",
        description: "",
        file: null,
      },
      errors: {},
      templates: {},
      uploadedTemplate: null,
      baseUrl: process.env.VUE_APP_VAVEURL,
      image: null,
      previewingTemplate: null,
    };
  },
  mounted() {
    this.getTemplates();
    let topic = "globus/vaveproxy/email-templates/#";
    MqttClient.subscribe(topic);
    EventBus.$on(topic, this.getTemplates);
  },
  beforeDestroy() {
    EventBus.$off("globus/vaveproxy/email-templates/#", this.getTemplates);
  },
  methods: {
    getTemplates(page = 1) {
      this.templates = {};
      axios.get("/api/admin/email-templates?per_page=10&page=" + page).then(
        (r) => {
          if (r.status == 200) {
            this.templates = r.data;
          }
        },
        (e) => {
          console.log(e);
        }
      );
    },
    deleteTemplate(id) {
      if (
        !confirm(
          "do you want to delete the template " +
            id +
            "? This action is irreversible"
        )
      ) {
        return;
      }
      axios.delete("/api/admin/email-templates/" + id).then(
        (r) => {
          this.getTemplates();
          if (r.status == 200) {
            this.$toast.success("Template deleted");
            return;
          }
          this.$toast.error("Problem deleting the template");
        },
        (e) => {
          this.$toast.error("Problem deleting the template");
          console.log(e);
        }
      );
    },
    onFileChange(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.template.file = files[0];
      console.log("file saved in variable");
    },
    uploadTemplate() {
      this.errors = {};
      let formData = new FormData();
      formData.append("template", this.template.file);
      formData.append("name", this.template.name);
      formData.append("description", this.template.description);
      axios
        .post("/api/admin/email-templates", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(
          (r) => {
            console.log(r);
            if (r.status == 201) {
              this.template = {
                name: "",
                description: "",
                file: null,
              };
              this.$toast.success("Template uploaded correctly");
              this.getTemplates();
            }
          },
          (e) => {
            this.$toast.error("Error uploading the template");
            if (e.response && e.response.data && e.response.data.errors) {
              console.log("setting errors");
              this.errors = e.response.data.errors;
            }
          }
        );

      // reader.onload = (e) => {
      //   vm.image = e.target.result;
      // };
      // reader.readAsDataURL(file);
    },
    removeTemplate: function () {
      this.template.file = null;
    },
    showPreview(template) {
      this.previewingTemplate = template;
      this.$refs.emailPreview.show();
    },
  },
  components: {
    pagination,
    WidgetConfigModal,
  },
};
</script>

<style lang="scss">
tbody tr {
  &:hover {
    background: #fafafa;
  }
}
.preview-iframe {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  width: 100%;
  min-height: 100%;
  padding-top: 4em;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
</style>